var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"space-around"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-dialog',{attrs:{"max-width":"1000"},on:{"click:outside":_vm.close,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.close.apply(null, arguments)}},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"orange","dark":""}},[_vm._v(" Resultados ")]),_c('v-card-text',[_c('v-container',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Busca por cualquier campo","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"search":_vm.search,"items":_vm.clients,"loading":_vm.fecthingClients,"loading-text":"Cargando... Por favor, espere","no-data-text":"No hay datos disponibles","footer-props":{
                    'items-per-page-text': 'Filas por página',
                    pageText: '{0}-{1} de {2}'
                  }},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
                  var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"transparent-button-icon",attrs:{"color":"green","icon":""},on:{"click":function($event){return _vm.select(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"25"}},[_vm._v(" mdi-check-all ")])],1)]}}],null,true)},[_c('span',[_vm._v("Presione seleccionar el cliente")])])]}}],null,true)})],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }