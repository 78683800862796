<template>
  <v-row justify="space-around">
    <v-col cols="auto">
      <v-dialog
        v-model="dialog"
        max-width="1000"
        @click:outside="close"
        @keydown.esc="close"
      >
        <!-- <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            v-bind="attrs"
            v-on="on"
          >
            From the bottom
          </v-btn>
        </template> -->
        <v-card>
          <v-toolbar
            color="primary"
            dark
          >
            Agregar Cliente
          </v-toolbar>
          <v-card-text>
            <v-container>
              <v-row
                justify="center"
              >
                <v-col
                  cols="12"
                >
                  <v-row
                    justify="center"
                  >
                    <!-- <v-col
                      sm="12"
                      md="3"
                      :style="{
                        marginTop: '-2rem',
                        display: 'flex',
                        justifyContent: 'center'
                      }"
                    >
                      <v-icon
                        size="50"
                      >
                        mdi-account
                      </v-icon>
                    </v-col> -->
                    <v-col
                      sm="12"
                      md="3"
                      class="mx-0"
                    >
                      <v-autocomplete
                        v-model="ciType"
                        :items="typesCi"
                        outlined
                      />
                    </v-col>
                    <v-col
                      class="mx-0"
                    >
                      <v-text-field
                        v-model="form.ci"
                        required
                        class="purple-input"
                        label="Documento de identidad*"
                        type="text"
                        outlined
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col
                  cols="12"
                  lg="6"
                >
                  <v-text-field
                    v-model="form.name"
                    required
                    class="purple-input"
                    label="Nombres*"
                    type="text"
                    outlined
                  />
                </v-col>
                <v-col
                  cols="12"
                  lg="6"
                >
                  <v-text-field
                    v-model="form.lastname"
                    required
                    class="purple-input"
                    label="Apellidos*"
                    type="text"
                    outlined
                  />
                </v-col>
                <v-col
                  cols="12"
                  lg="6"
                >
                  <v-text-field
                    v-model="form.email"
                    required
                    class="purple-input"
                    label="Email"
                    type="Email"
                    name="email"
                    outlined
                  />
                </v-col>
                <v-col
                  cols="12"
                  lg="6"
                >
                  <!-- <v-text-field
                    v-model="form.phone"
                    required
                    class="purple-input"
                    label="Teléfono*"
                    type="text"
                    name="phone"
                    outlined
                  /> -->
                  <vue-tel-input-vuetify
                    v-model="form.phone"
                    label="Teléfono 1*"
                    return-masked-value
                    placeholder="0000-0000000"
                    outlined
                  />
                </v-col>
                <v-col
                  cols="12"
                  lg="6"
                >
                  <!-- <v-text-field
                    v-model="form.phone"
                    required
                    class="purple-input"
                    label="Teléfono*"
                    type="text"
                    name="phone"
                    outlined
                  /> -->
                  <vue-tel-input-vuetify
                    v-model="form.phone2"
                    label="Teléfono 2"
                    return-masked-value
                    placeholder="0000-0000000"
                    outlined
                  />
                </v-col>
                <v-col
                  cols="12"
                  lg="6"
                >
                  <!-- <v-text-field
                    v-model="form.phone"
                    required
                    class="purple-input"
                    label="Teléfono*"
                    type="text"
                    name="phone"
                    outlined
                  /> -->
                  <vue-tel-input-vuetify
                    v-model="form.phone3"
                    label="Teléfono 3"
                    return-masked-value
                    placeholder="0000-0000000"
                    outlined
                  />
                </v-col>
                <v-col
                  cols="12"
                >
                  <v-textarea
                    v-model="form.direction"
                    outlined
                    name="address"
                    label="Direccion"
                    no-resize
                  />
                </v-col>
                <small>Los campos indicados con <span class="red--text">(*)</span> son requeridos</small>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn
              text
              @click="close"
            >
              Cerrar
            </v-btn>
            <v-btn
              color="secondary"
              :disabled="disabled"
              :loading="fecthingClients"
              @click="save"
            >
              Agregar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  export default {
    name: 'NewClientDialog',

    props: {
      dialog: {
        type: Boolean,
        default: () => true,
      },
      data: {
        type: Object,
        default: () => true,
      },
    },

    data () {
      return {
        typesCi: ['V', 'E', 'P', 'J', 'G'],
        ciType: 'V',
        form: {
          ci: '',
          name: '',
          lastname: '',
          email: '',
          phone: '',
          phon2: '',
          phone3: '',
          direction: '',
        },
      }
    },

    computed: {
      ...mapState([
        'fecthingClients',
      ]),

      disabled () {
        return !(this.form.ci && this.form.name && this.form.lastname && this.form.phone)
      },
    },

    watch: {
      data (v) {
        this.ciType = v?.ciType
        this.form.ci = v?.ci
      },
    },

    methods: {
      ...mapActions([
        'createClient',
      ]),

      close () {
        this.$emit('close')
      },

      // afterSubmit () {
      //   this.data = {
      //     ci: '',
      //     name: '',
      //     lastname: '',
      //     email: '',
      //     phone: '',
      //     direction: '',
      //   }

      //   this.close()
      // },

      async save () {
        this.form.ci = this.ciType + '-' + this.form.ci
        // // // // // // // // // // // // // // // // // // // // // // // // console.log('nuevoCliente', this.form)
        await this.createClient(this.form)
        // this.afterSubmit()
        this.$emit('search', this.form.ci)
        this.form = {
          ci: '',
          name: '',
          lastname: '',
          email: '',
          phone: '',
          direction: '',
        }

        this.close()
      },
    },
  }
</script>
