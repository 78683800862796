<template>
  <v-row justify="space-around">
    <v-col cols="auto">
      <v-dialog
        v-model="dialog"
        max-width="1000"
        @click:outside="close"
        @keydown.esc="close"
      >
        <!-- <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            v-bind="attrs"
            v-on="on"
          >
            From the bottom
          </v-btn>
        </template> -->
        <v-card>
          <v-toolbar
            color="orange"
            dark
          >
            Resultados
          </v-toolbar>
          <v-card-text>
            <v-container>
              <v-row
                justify="center"
              >
                <v-col
                  cols="12"
                >
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Busca por cualquier campo"
                    single-line
                    hide-details
                  />
                </v-col>

                <v-col
                  cols="12"
                >
                  <v-data-table
                    :headers="headers"
                    :search="search"
                    :items="clients"
                    :loading="fecthingClients"
                    loading-text="Cargando... Por favor, espere"
                    no-data-text="No hay datos disponibles"
                    :footer-props="{
                      'items-per-page-text': 'Filas por página',
                      pageText: '{0}-{1} de {2}'
                    }"
                  >
                    <template v-slot:[`item.actions`]="{ item: item }">
                      <v-tooltip
                        bottom
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            class="transparent-button-icon"
                            color="green"
                            icon
                            v-bind="attrs"
                            v-on="on"
                            @click="select(item)"
                          >
                            <v-icon size="25">
                              mdi-check-all
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>Presione seleccionar el cliente</span>
                      </v-tooltip>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
  import { mapActions, mapMutations, mapState } from 'vuex'
  import { FETCH_CLIENTS_SUCCESS } from '../../../../store/mutations-types/clients'
  export default {
    name: 'SearchClientDialog',

    props: {
      dialog: {
        type: Boolean,
        default: () => true,
      },
      data: {
        type: Object,
        default: () => true,
      },
    },

    data () {
      return {
        search: '',
        headers: [
          {
            text: 'Documento de indentidad',
            value: 'ci',
          },
          {
            text: 'Nombre',
            value: 'name',
          },
          {
            text: 'Apellido',
            value: 'lastname',
          },
          {
            text: 'Acciones',
            value: 'actions',
            align: 'center',
            sortable: false,
          },
        ],
      }
    },

    computed: {
      ...mapState([
        'fecthingClients',
        'clients',
      ]),
    },

    watch: {
      async dialog (v) {
        if (v) {
          await this.fetchAllClient()
        }
      },
    },

    async created () {
      await this.fetchAllClient()
    },

    async mounted () {
      await this.fetchAllClient()
    },

    methods: {
      ...mapActions([
        'fetchAllClient',
      ]),

      ...mapMutations({
        setClient: FETCH_CLIENTS_SUCCESS,
      }),

      close () {
        this.$emit('close')
      },

      select (item) {
        // // // // // // // // // // // // // // console.log(item)
        this.setClient(item)

        this.close()
      },
    },
  }
</script>

<style scoped>
  .transparent-button-icon {
    background-color: transparent !important;
  }
</style>
