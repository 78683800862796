<template>
  <div>
    <!-- <pre>{{ getClients }}</pre> -->
    <v-row v-if="!isSeller">
      <!-- <v-col
        cols="12"
      >
        <h1
          style="color: rgb( 100, 100, 100);"
        >
          Vendedor
        </h1>
      </v-col> -->
      <!-- <v-col
        cols="12"
        md="6"
      >
        <v-autocomplete
          v-model="sellerId"
          label="Vendedor"
          :disabled="fetchingUser"
          :loading="fetchingUser"
          :items="getSellers"
          item-value="userId"
          no-data-text="No hay personas autorizadas a vender en esta sucursal"
          filled
        />
      </v-col> -->
    </v-row>
    <v-row
      class="mt-3"
    >
      <!-- <v-col
        cols="12"
      >
        <h1
          style="color: rgb( 100, 100, 100);"
        >
          Cliente
        </h1>
      </v-col> -->
      <!-- <v-col
        cols="12"
        md="2"
      >
      <v-autocomplete
          v-model="ciType"
          :items="typesCi"
          filled
        />
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <v-text-field
          v-model="ci"
          label="Numero de indentificación"
          filled
        />
      </v-col>
      <v-col
        cols="12"
        md="1"
      >
        <v-btn
          v-if="searchButton"
          fab
          dark
          :color="color"
          :loading="fecthingClients"
          @click="search"
        >
          <v-icon dark>
            mdi-magnify
          </v-icon>
        </v-btn>

        <v-btn
          v-else
          fab
          dark
          :color="color"
          @click="dialog = true"
        >
          <v-icon dark>
            mdi-plus
          </v-icon>
        </v-btn>
      </v-col> -->
      <!-- <v-col
        cols="12"
        md="5"
      > -->
      <!-- @click="dialogS = true" -->
      <!-- <v-autocomplete
          v-model="clientSelected"
          label="Nombre del cliente"
          :items="getClients"
          return-object
          no-data-text="No hay datos disponibles"
          filled
          item-text="title"
        /> -->
      <!-- </v-col> -->

      <v-col
        cols="12"
        md="3"
      >
        <!-- <pre>{{ clientsSearch }}</pre> -->
        <v-text-field
          v-model="clientsSearch.ci"
          disabled
          label="Numero de indentificación"
          filled
        />
      </v-col>

      <v-col
        cols="4"
        md="1"
      >
        <v-btn
          fab
          dark
          :color="color"
          @click="dialog = true"
        >
          <v-icon dark>
            mdi-plus
          </v-icon>
        </v-btn>
      </v-col>
      <v-col
        cols="4"
        md="1"
      >
        <v-btn
          fab
          dark
          :color="color"
          @click="dialogS = true"
        >
          <v-icon dark>
            mdi-magnify
          </v-icon>
        </v-btn>
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <v-autocomplete
          v-model="sellerId"
          label="Vendedor"
          :disabled="fetchingUser"
          :loading="fetchingUser"
          :items="getSellers"
          item-value="userId"
          no-data-text="No hay personas autorizadas a vender en esta sucursal"
          filled
        />
      </v-col>
      <v-col
        cols="4"
        md="1"
        sm="4"
        class="text-center"
      >
        <v-btn
          class="mx-2"
          fab
          dark
          color="secondary"
          @click="showAdd = true"
        >
          <v-icon dark>
            mdi-plus
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <new-client-dialog
      :dialog="dialog"
      :data="propAdd"
      @close="dialog = false"
      @search="addSearch"
    />

    <search-client-dialog
      :dialog="dialogS"
      :data="propAddS"
      @close="dialogS = false"
    />
    <!--
    <v-row
      justify="center"
    >
      <v-col
        cols="12"
        class="text-right"
      >
        <h1
          :style="{
            fontSize: '40px'
          }"
        >
          <!- <span class="green--text">{{ formatPrice(!gData.calculateIVA ? subTotal : total) }}</span> <span class="grey--text">|</span> <span class="orange--text">{{ formatPrice((!gData.calculateIVA ? subTotal : total) * getCurrencyValue('Euro'), 'EUR') }}</span> <span class="grey--text">|</span> <span class="info--text">{{ formatPrice((!gData.calculateIVA ? subTotal : total) * getCurrencyValue('Bolivar'), 'VES') }}</span>
          <span class="green--text">{{ formatPrice(total) }}</span> <span class="grey--text">|</span> <span class="orange--text">{{ formatPrice((total) * getCurrencyValue('Euro'), 'EUR') }}</span> <span class="grey--text">|</span> <span class="info--text">{{ formatPrice((total) * getCurrencyValue('Bolivar'), 'VES') }}</span>
        </h1>
      </v-col>
    </v-row>
    -->
    <!-- <v-card
      class="mb-12"
      color="grey lighten-1"
      height="200px"
    /> -->

    <!-- /////////////////////////////////////////////////////////////////////////// -->

    <v-row
      style="margin-top: -75px"
      justify="center"
    >
      <v-col
        cols="12"
      >
        <span
          v-if="alert"
          class="red--text"
        >
          La carga no puede ser mayor a disponibilidad del producto
        </span>
      </v-col>
      <!-- <v-col
        cols="12"
        md="6"
        lg="6"
      >
        <v-autocomplete
          v-model="productSelected"
          label="Productos"
          :loading="fetchingProducts"
          :disabled="fetchingProducts"
          :items="getProducts"
          item-text="description"
          no-data-text="No hay coincidencia"
          return-object
          outlined
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
        lg="2"
      >
        <v-autocomplete
          v-model="productSelected.typePrice"
          label="Tipo de precio"
          :loading="fetchingProducts"
          :disabled="fetchingProducts"
          :items="selectTypePrice"
          item-text="name"
          no-data-text="No hay coincidencia"
          item-value="value"
          outlined
        />
      </v-col>
      <v-col
        cols="12"
        md="3"
        lg="2"
      >
        <v-text-field
          v-model="packingCant"
          type="number"
          min="1"
          :max="getCantPackingBySelectProduct"
          :rules="requiredFieldRules('Cantidad es requerido')"
          outlined
          :label="'Cantidad de ' + packingUnit"
          required
        />
      </v-col>
      <v-col
        cols="12"
        md="3"
        lg="2"
      >
        <v-text-field
          v-model="cant"
          type="number"
          min="1"
          :max="getCantbySelectProduct"
          :rules="requiredFieldRules('Cantidad es requerido')"
          outlined
          :label="'Cantidad de ' + saleUnit"
          required
        />
      </v-col>
      <v-col
        cols="12"
        md="3"
        lg="2"
      >
        <template>
          <v-text-field
            v-model="getCantPackingBySelectProduct"
            type="number"
            outlined
            :loading="fetchingProducts"
            :label="'Disponible de ' + packingUnit"
            disabled
          />
        </template>
      </v-col>
      <v-col
        cols="12"
        md="3"
        lg="2"
      >
        <template>
          <v-text-field
            v-model="getCantbySelectProduct"
            type="number"
            outlined
            :loading="fetchingProducts"
            :label="'Disponible de ' + saleUnit"
            disabled
          />
        </template>
      </v-col> -->

      <!-- /////////////////////////////////////////////////////////////////////////// -->

      <!-- <v-col
        cols="12"
        lg="2"
        sm="4"
      >
        <template>
          <v-text-field
            v-model="getCantbySelectProduct"
            type="number"
            outlined
            :loading="fetchingProducts"
            :label="'Disponible de ' + saleUnit"
            disabled
          />
        </template>
      </v-col>
      <v-col
        cols="12"
        lg="2"
        sm="4"
      >
        <template>
          <v-text-field
            v-model="getCantPackingBySelectProduct"
            type="number"
            outlined
            :loading="fetchingProducts"
            :label="'Disponible de ' + packingUnit"
            disabled
          />
        </template>
      </v-col>
      <v-col
        cols="12"
        :lg="gData.calculateIVA ? '1' : '2'"
        :sm="gData.calculateIVA ? '4' : '6'"
      >
        <v-text-field
          v-model="cant"
          type="number"
          min="1"
          :max="getCantbySelectProduct"
          :rules="requiredFieldRules('Cantidad es requerido')"
          outlined
          :label="'Cantidad de ' + saleUnit"
          required
        />
      </v-col>
      <v-col
        cols="12"
        :lg="gData.calculateIVA ? '1' : '2'"
        :sm="gData.calculateIVA ? '4' : '6'"
      >
        <v-text-field
          v-model="packingCant"
          type="number"
          min="1"
          :max="getCantPackingBySelectProduct"
          :rules="requiredFieldRules('Cantidad es requerido')"
          outlined
          :label="'Cantidad de ' + packingUnit"
          required
        />
      </v-col> -->
      <!-- /////////////////////////////////////////////////////////////////////////// -->

      <!-- <v-col
        cols="12"
        md="12"
        lg="2"
      >
        <template>
          <v-text-field
            :value="productSelected[productSelected.typePrice] * cant || 0"
            type="number"
            outlined
            :loading="fetchingProducts"
            label="Monto"
            disabled
          />
        </template>
      </v-col>
      <v-col
        v-if="gData.calculateIVA"
        cols="12"
        sm="4"
        lg="2"
      >
        <template>
          <v-text-field
            :value="(productSelected[productSelected.typePrice] * cant) * (productSelected.tax/100) || 0"
            type="number"
            outlined
            :loading="fetchingProducts"
            label="Monto del impuesto"
            disabled
          />
        </template>
      </v-col>
      <v-col
        v-if="gData.calculateIVA"
        cols="12"
        sm="12"
        lg="2"
      >
        <template>
          <v-text-field
            :value="productSelected[productSelected.typePrice] * cant + (productSelected[productSelected.typePrice] * cant) * (productSelected.tax/100) || 0"
            type="number"
            outlined
            :loading="fetchingProducts"
            label="Monto Total"
            disabled
          />
        </template>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        lg="6"
        class="text-center"
      >
        <h1
          :style="{
            fontSize: '40px'
          }"
        >
          <span class="green--text">{{ formatPrice(total) }}</span> <span class="grey--text">|</span> <span class="orange--text">{{ formatPrice((total) * getCurrencyValue('Euro'), 'EUR') }}</span> <span class="grey--text">|</span> <span class="info--text">{{ formatPrice((total) * getCurrencyValue('Bolivar'), 'VES') }}</span>
        </h1>
      </v-col> -->
      <!-- <v-col
        cols="12"
        md="1"
        sm="12"
        class="text-center"
      >
        <v-btn
          class="mx-2"
          fab
          dark
          color="secondary"
          large
          @click="addProductList"
        >
          <v-icon dark>
            mdi-plus
          </v-icon>
        </v-btn>
      </v-col> -->
      <!-- /////////////////////////////////////////////////////////////////////////// -->

      <!-- <v-row justify="end">
        <v-col
          cols="12"
          md="1"
          sm="12"
          class="text-center"
        >
          <v-btn
            class="mx-2"
            fab
            dark
            color="secondary"
            large
            @click="showAdd = true"
          >
            <v-icon dark>
              mdi-plus
            </v-icon>
          </v-btn>
        </v-col>
      </v-row> -->
      <v-col cols="12">
        <v-data-table
          class="elevation-0"
          no-data-text="No hay productos cargados"
          :headers="headers"
          :items="data.products"
          :items-per-page="-1"
          :hide-default-footer="true"
          :footer-props="{
            'items-per-page-text': 'Filas por página',
            pageText: '{0}-{1} de {2}'
          }"
        >
          <template
            v-if="showAdd"
            v-slot:[`body.prepend`]="{}"
          >
            <br>
            <tr
              class="text-center"
            >
              <th>
                <v-autocomplete
                  v-model="productSelected"
                  :loading="fetchingProducts"
                  :disabled="fetchingProducts"
                  :items="getProducts"
                  item-text="name"
                  no-data-text="No hay coincidencia"
                  return-object
                />
              </th>
              <th>
                <v-autocomplete
                  v-model="productSelected.typePrice"
                  :loading="fetchingProducts"
                  :disabled="fetchingProducts"
                  :items="selectTypePrice"
                  item-text="name"
                  no-data-text="No hay coincidencia"
                  item-value="value"
                />
              </th>
              <th>
                <v-text-field
                  v-model="packingCant"
                  type="number"
                  min="1"
                  :max="getCantPackingBySelectProduct"
                  :rules="requiredFieldRules('Cantidad es requerido')"
                  required
                  :hint="'Disponibles: ' + getCantPackingBySelectProduct"
                  persistent-hint
                />
              </th>
              <!-- <th><h2>{{ packingUnit }}</h2></th> -->
              <th>
                <v-text-field
                  v-model="cant"
                  type="number"
                  min="1"
                  :max="getCantbySelectProduct"
                  :rules="requiredFieldRules('Cantidad es requerido')"
                  required
                  :hint="'Disponibles: ' + getCantbySelectProduct"
                  persistent-hint
                />
              </th>
              <!-- <th><h2>{{ saleUnit }}</h2></th> -->
              <th><h2>{{ formatPrice(productSelected[productSelected.typePrice] * cant || 0) }}</h2></th>
              <th>
                <v-btn
                  icon
                  color="green"
                  class="transparent-button-icon"
                  @click="addProductList"
                >
                  <v-icon size="28">
                    mdi-check-all
                  </v-icon>
                </v-btn>
              </th>
            </tr>
          </template>

          <template v-slot:[`item.typePrice`]="{ item: product }">
            <v-select
              v-model="product.typePrice"
              :items="selectTypePrice"
              item-text="name"
              item-value="value"
              @change="calculateTotals"
            />
          </template>
          <template v-slot:[`item.price`]="{ item: product }">
            <span>{{ formatPrice(product[product.typePrice] * product.cant) }}</span>
          </template>

          <template v-slot:[`item.taxPrice`]="{ item: product }">
            <span>{{ formatPrice((product[product.typePrice] * product.cant) * (product.tax/100)) }}</span>
          </template>

          <template v-slot:[`item.totalPrice`]="{ item: product }">
            <span class="green--text">{{ formatPrice((product[product.typePrice] * product.cant) + (product[product.typePrice] * product.cant) * (product.tax/100)) }}</span>
          </template>

          <template v-slot:[`item.actions`]="{ item: product }">
            <v-btn
              color="red"
              class="transparent-button-icon"
              icon
              @click="deleteProductList(product.productId || product.id)"
            >
              <v-icon>
                mdi-delete
              </v-icon>
            </v-btn>
          </template>
        </v-data-table>
        <hr>
      </v-col>
      <!-- <v-col cols="12">
        <v-textarea
          v-model="data.description"
          outlined
          name="input-7-4"
          label="Observaciones"
          no-resize
        />
      </v-col> -->
    </v-row>

    <v-row>
      <v-col
        cols="12"
        md="12"
        lg="6"
      >
        <v-textarea
          v-model="data.description"
          label="Observaciones"
          outlined
          rows="6"
          no-resize
        />
      </v-col>
      <!-- <v-col
        cols="12"
        md="12"
        lg="2"
      >
        <v-text-field
          v-model="discountPercen"
          label="% de descuento"
          type="number"
          outlined
          min="0"
          prepend-inner-icon="mdi-percent-outline"
        />
      </v-col> -->
      <!-- <v-col
        cols="12"
        md="12"
        lg="4"
      >
        <span v-if="gData.calculateIVA">
          <h2> <span class="grey--text">Subtotal987: </span> <span class="green--text">{{ formatPrice(subTotal) }}</span></h2>
          <h3> <span class="grey--text">Exento de IVA: </span> <span class="green--text">{{ formatPrice(withoutTax) }}</span></h3>
          <h3> <span class="grey--text">Gravamen: </span> <span class="green--text">{{ formatPrice(gravamen) }}</span></h3>
          <h3><span class="grey--text">Total del IVA: </span> <span class="green--text">{{ formatPrice(taxTotal) }}</span></h3>
          <hr>
          <br>
        </span>
        <h2> <span class="grey--text">Subtotal: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> <span class="green--text">{{ formatPrice(subTotal) }}</span></h2>
        <h2> <span class="grey--text">% de descuento: &nbsp;&nbsp;&nbsp;&nbsp;</span> <span class="green--text">{{ discountPercen }}%</span></h2>
        <v-row class="mt-3">
          <v-col cols="6">
            <h2> <span class="grey--text">% de descuento: </span></h2>
          </v-col>
          <v-text-field
            v-model="discountPercen"
            dense
            min="0"
            prepend-inner-icon="mdi-percent-outline"
          />
        </v-row>
        <h2> <span class="grey--text">Monto descuento: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> <span class="green--text">{{ formatPrice(montDiscount) }}</span></h2>
        <h1>Total: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span class="green--text">{{ formatPrice(total) }}</span> <span class="grey--text">|</span> <span class="orange--text">{{ formatPrice((total) * getCurrencyValue('Euro'), 'EUR') }}</span> <span class="grey--text">|</span> <span class="info--text">{{ formatPrice((total) * getCurrencyValue('Bolivar'), 'VES') }}</span></h1>
      </v-col> -->
      <!-- <h1>Total: <span class="green--text">{{ formatPrice(!gData.calculateIVA ? subTotal : total) }}</span> <span class="grey--text">|</span> <span class="orange--text">{{ formatPrice((!gData.calculateIVA ? subTotal : total) * getCurrencyValue('Euro'), 'EUR') }}</span> <span class="grey--text">|</span> <span class="info--text">{{ formatPrice((!gData.calculateIVA ? subTotal : total) * getCurrencyValue('Bolivar'), 'VES') }}</span></h1> -->
      <v-col
        cols="6"
        md="3"
      >
        <h2> <span class="grey--text">Subtotal: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></h2>
        <h2
          style="margin-top: 20px"
        >
          <span
            class="grey--text"
          >
            % de descuento: &nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </h2>
        <h2 style="margin-top: 20px">
          <span class="grey--text">Monto descuento: &nbsp;</span>
        </h2>
        <h1
          style="margin-top: 20px"
        >
          Total: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </h1>
      </v-col>
      <v-col
        cols="6"
        md="3"
      >
        <h2> <span class="green--text">{{ formatPrice(subTotal) }}</span></h2>
        <v-text-field
          v-model="discountPercen"
          type="number"
          min="0"
          prepend-inner-icon="mdi-percent-outline"
        />
        <h2> <span class="green--text">{{ formatPrice(montDiscount) }}</span></h2>
        <h1
          style="margin-top: 20px"
        >
          <span class="green--text">{{ formatPrice(total) }}</span> <span class="grey--text">|</span> <span class="orange--text">{{ formatPrice((total) * getCurrencyValue('Euro'), 'EUR') }}</span> <span class="grey--text">|</span> <span class="info--text">{{ formatPrice((total) * getCurrencyValue('Bolivar'), 'VES') }}</span>
        </h1>
      </v-col>
    </v-row>

    <v-dialog
      v-model="modalToAddClient"
      max-width="480"
    >
      <v-card>
        <v-card-title class="text-h2">
          No se ha encontrado un cliente con estos datos
        </v-card-title>

        <v-card-text class="text-center grey--text">
          ¿Desea agregar el cliente con el documento {{ ciType }}-{{ ci }}?
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            color="red darken-1"
            text
            @click="modalToAddClient = false"
          >
            Cancelar
          </v-btn>

          <v-btn
            color="green darken-1"
            text
            @click="modalToAddClient = false; dialog = true; propAdd = { ci: ci, ciType: ciType }"
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row
      justify="end"
    >
      <v-col
        cols="12"
        md="3"
        lg="2"
      >
        <v-btn
          text
          block
          @click="back"
        >
          Atras
        </v-btn>
      </v-col>
      <v-col
        cols="12"
        md="3"
        lg="2"
      >
        <v-btn
          color="secondary"
          block
          @click="next"
        >
          Continuar
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import NewClientDialog from './NewClientDialog.vue'
  import SearchClientDialog from './SearchClientDialog.vue'
  import { mapActions, mapState, mapMutations } from 'vuex'
  import { FETCH_CLIENTS_SUCCESS } from '../../../../store/mutations-types/clients'
  import { SELLER } from '../../../../const'
  export default {
    name: 'StepOne',

    components: {
      NewClientDialog,
      SearchClientDialog,
    },

    props: {
      gData: {
        type: Object,
        default: () => {},
      },
    },

    data () {
      return {
        alert: false,
        cant: 20,
        packingCant: 1,
        typesCi: ['V', 'E', 'P', 'J', 'G'],
        modalToAddClient: false,
        clientSelected: {},
        selectTypePrice: [
          // {
          //   name: 'Al detal',
          //   value: 'retailPrices',
          // },
          {
            name: 'Mayor',
            value: 'wholesalePrices',
          },
          {
            name: 'Gran mayor',
            value: 'bigWholesalePrices',
          },
        ],
        showAdd: false,
        dialogS: false,
        propAddS: {},
        propAdd: {},
        ciType: 'V',
        dialog: false,
        searchButton: false,
        ci: '',
        getCodeProductsDescription: [],
        sellerId: null,
        data: {
          description: '',
          products: [],
          relatedWarehouseId: null,
        },
        productSelected: { typePrice: 'bigWholesalePrices' },
        subTotal: 0,
        withoutTax: 0,
        gravamen: 0,
        taxTotal: 0,
        discountPercen: 0,
        montDiscount: 0,
        total: 0,
      }
    },

    computed: {
      ...mapState([
        'fecthingClients',
        'clientsSearch',
        'fetchingProducts',
        'products',
        'storeInventory',
        'currencies',
        'userBySucursalId',
        'fetchingUser',
        'user',
        'clients',
      ]),

      getClients () {
        let res = this.clients.filter(item => item.enable)

        res = res.map(item => {
          return {
            ...item,
            title: `${item.ci} - ${item.name} ${item.lastname}`,
          }
        })

        return res
      },

      saleUnit () {
        // // // // // // // // // // // console.log(this.productSelected.salesUnit)
        return this.productSelected.salesUnit || '(unidad)'
      },

      packingUnit () {
        return this.productSelected.packingUnit || '(empaque)'
      },

      isSeller () {
        return this.is(SELLER)
      },

      headers () {
        return this.gData.calculateIVA ? [
          {
            text: 'Nombre del producto',
            value: 'description',
          },
          {
            text: 'Tipo de precio',
            value: 'typePrice',
            sortable: false,
          },
          {
            text: 'Cantidad',
            value: 'cant',
          },
          {
            text: 'Precio',
            value: 'price',
          },
          {
            text: '% de IVA',
            value: 'tax',
          },
          {
            text: 'Valor del impuesto',
            value: 'taxPrice',
          },
          {
            text: 'Total del producto',
            value: 'totalPrice',
          },
          {
            text: 'Acciones',
            value: 'actions',
            sortable: false,

          },
        ] : [
          {
            text: 'Productos',
            value: 'description',
          },
          {
            text: 'Tipo de precio',
            value: 'typePrice',
            sortable: false,
          },
          {
            text: 'Rollos',
            value: 'packingCant',
          },
          // {
          //   text: 'Unidad de empaque',
          //   value: 'packingUnit',
          // },
          {
            text: 'KG',
            value: 'cant',
          },
          // {
          //   text: 'Unidad',
          //   value: 'salesUnit',
          // },
          {
            text: 'Precio',
            value: 'price',
          },
          {
            text: 'Acciones',
            value: 'actions',
            sortable: false,

          },
        ]
      },

      getProducts () {
        const newProducts = this.products
          .filter(i => i.status && this.getProdusctAvailable(i.id || i.productId))
          .map(i => ({
            ...i,
            name: i.codigo + ' - ' + i.description,
            productId: i.productId ?? i.id,
            typePrice: 'bigWholesalePrices',
            id: null,
          }))

        newProducts.forEach(p => {
          delete p.id
        })

        return newProducts
      },

      getCantbySelectProduct () {
        let res = this.storeInventory.filter(item => item?.productId === this.productSelected?.productId)
        res = res[0]?.quantity_available || 0

        const chargedProduct = this.data.products.filter(item => item.productId === this.productSelected?.productId)
        return res - (chargedProduct[0]?.cant || 0)
      },

      getCantPackingBySelectProduct () {
        let res = this.storeInventory.filter(item => item?.productId === this.productSelected?.productId)
        res = res[0]?.quantity_packing_available || 0

        const chargedProduct = this.data.products.filter(item => item.productId === this.productSelected?.productId)
        return res - (chargedProduct[0]?.cant || 0)
      },

      nameClient () {
        // return this.clientsSearch !== {} ? this.clientsSearch?.name + ' ' + this.clientsSearch?.lastname : ''
        return this.clientsSearch?.name ? this.clientsSearch?.name + ' ' + this.clientsSearch?.lastname : ''
      },

      color () {
        return this.clientsSearch?.name ? 'success' : 'indigo'
      },

      getSellers () {
        return this.userBySucursalId.map(item => {
          return {
            ...item,
            text: `${item.name} ${item.lastName} ${item.ci}`,
          }
        })
      },
    },

    watch: {
      ci (v) {
        this.searchButton = !!v
      },

      'data.products' (v) {
        this.calculateTotals()
      },

      discountPercen (v) {
        this.calculateTotals()
      },

      'gData.calculateIVA' (v) {
        // // // // // // // // // // // // // // // // // // // // console.log('Se actualiza', v)
      },

      clientSelected (v) {
        // // // // console.log('paso')
        this.select(v)
      },

      products (v) {
        // // // // // // // // // // // // // // // // // // // // // console.log('cambio', v)
      },
    },

    mounted () {
      this.sellerId = this.user.id
      // // // // // // // // // // // // // // // // // // // // console.log('IVA', this.gData.calculateIVA)
      // eslint-disable-next-line
      if (this.clientsSearch.hasOwnProperty('ci')) {
        this.ciType = this.clientsSearch?.ci?.split('-')[0]
        this.ci = this.clientsSearch?.ci?.split('-')[1]
      }
    },

    methods: {
      ...mapActions([
        'fetchClient',
      ]),

      ...mapMutations({
        setClient: FETCH_CLIENTS_SUCCESS,
      }),

      select (item) {
        this.setClient(item)
      },

      getProdusctAvailable (id) {
        const filter = this.storeInventory.filter(item => item?.productId === id)
        // // // // // // // // // // // // // // // // // // // // // console.log('available', filter)
        return filter.length > 0 && filter[0].quantity_available > 0
      },

      getCurrencyValue (currency) {
        return this.currencies.filter(item => item.name === currency)[0]?.value
      },

      calculateTotals () {
        let subTotal = 0
        let withoutTax = 0
        let gravamen = 0
        let taxTotal = 0
        let total = 0

        this.data.products.forEach(item => {
          subTotal = subTotal + (item[item.typePrice] * item.cant)
          taxTotal = taxTotal + ((item[item.typePrice] * item.cant) * (item.tax / 100))
          if (item.tax === 0) {
            withoutTax = withoutTax + (item[item.typePrice] * item.cant)
          } else {
            gravamen = gravamen + (item[item.typePrice] * item.cant)
          }
        })

        total = subTotal // + taxTotal

        // // // // // // // // // // // // // console.log('TOTAL', total)
        const discountAmount = (total * this.discountPercen) / 100
        // // // // // // // // // // // // // console.log('SAM', discountAmount)

        // // // // // // // // // // // // // console.log('TOTAL', total)
        total = total - discountAmount

        // // // // // // // // // // // // // console.log('TOTAL', total)
        this.subTotal = subTotal
        this.withoutTax = withoutTax
        this.gravamen = gravamen
        this.taxTotal = taxTotal
        this.montDiscount = discountAmount
        this.total = total
      },

      formatPrice (v, type = 'USD') {
        return new Intl.NumberFormat(
          'en-ve',
          { style: 'currency', currency: type, minimumFractionDigits: 2 },
        ).format(v)
      },

      addProductList () {
        if (this.cant <= this.getCantbySelectProduct) {
          if (this.productSelected && this.cant) {
            // // // // // // // // // // // // // // // // // // // // // // // // console.log('producto sleccionado', this.productSelected)
            if (this.data.products.filter(item => item.productId === this.productSelected.productId).length >= 1) {
              this.data.products = this.data.products.map(item => item.productId === this.productSelected.productId ? ({
                ...item,
                cant: Number(item.cant) + Number(this.cant),
                packingCant: Number(item.packingCant) + Number(this.packingCant),
              }) : item)
            } else {
              this.data.products.unshift({ ...this.productSelected, cant: this.cant, packingCant: this.packingCant })
            }
          }
          this.alert = false
          this.productSelected = { typePrice: 'bigWholesalePrices' }
          this.packingCant = 1
          // this.productSelected = null
          this.cant = 20
        } else {
          this.alert = true
        }
        this.showAdd = false
      },

      deleteProductList (id) {
        this.data.products = this.data.products.filter(item => item.productId !== id)
      },

      requiredFieldRules (text) {
        return [
          v => !!v || text,
        ]
      },

      assingMax () {
        this.cant = this.getCantbySelectProduct
      },

      is (rol) {
        return this.user?.authorization.filter(auth => auth.roleId === rol).length > 0 || false
      },

      next () {
        const data = {
          description: this.data.description,
          products: this.data.products,
          client: this.clientsSearch,
          subTotal: this.subTotal,
          withoutTax: this.withoutTax,
          gravamen: this.gravamen,
          taxTotal: this.taxTotal,
          total: this.total,
          userId: this.isSeller ? this.user.id : this.sellerId,
          discount: this.discountPercen,
        }

        // // // // // // // // // // // // // // // // // // // // // // console.log('dataSub', data)
        this.$emit('submitData', data)
        this.$emit('next', 2)
      },

      back () {
        this.$emit('back', 1)
      },

      async addSearch (ci) {
        // // // // // // // // // // // // // // // // // // // // // // // // console.log('CI', ci)
        this.ciType = ci.split('-')[0]
        this.ci = ci.split('-')[1]
        await this.fetchClient(ci)
      },

      async search () {
        await this.fetchClient(this.ciType + '-' + this.ci)
        // // // // // // // // // // // // // // // // // // // // // console.log('SEARCH', this.clientsSearch)
        if (this.clientsSearch === undefined) {
          this.modalToAddClient = true
          // // // // // // // // // // // // // // // // // // // // // // console.log('Man modal para agregar')
        }
      },
    },
  }
</script>

<style scoped>
  .transparent-button-icon {
    background-color: transparent !important;
  }
</style>
