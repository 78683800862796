<template>
  <div>
    <!-- <v-card
      class="mb-12"
      color="grey lighten-1"
      height="200px"
    /> -->
    <!-- <pre>{{ currencies }}</pre> -->
    <v-row
      justify="center"
    >
      <v-col
        cols="12"
        class="text-center"
      >
        <h1
          :style="{
            fontSize: '20px',
            color: 'gray'
          }"
        >
          Restante por pagar
        </h1>
        <br>
        <h1>
          <span class="green--text">{{ formatPrice(calculateTotal) }}</span>
        </h1>
        <h1>
          <span class="orange--text">{{ formatPrice(calculateTotal * getCurrencyValue('Euro'), 'EUR') }}</span>
        </h1>
        <h1>
          <span class="info--text">{{ formatPrice(calculateTotal * getCurrencyValue('Bolivar'), 'VES') }}</span>
        </h1>
      </v-col>
      <v-container>
        <v-col
          v-if="actionsBusiness === DIRECT_SALE"
          cols="11"
        >
          <v-switch
            v-model="toCredit"
            color="blue"
            label="Pago a crédito"
          />
        </v-col>
      </v-container>
    </v-row>

    <v-row
      justify="center"
    >
      <v-col
        cols="12"
        class="text-left"
      >
        <h1
          class="grey--text"
        >
          Pagos
        </h1>
      </v-col>
      <v-col
        cols="12"
        md="4"
        lg="3"
      >
        <v-autocomplete
          v-model="paymentMethodSelected"
          label="Métodos de pago"
          :loading="fetchingPaymentsMethods"
          :disabled="fetchingPaymentsMethods || toCredit"
          :items="getPaymentMethods"
          item-text="description"
          no-data-text="No hay coincidencia"
          return-object
          outlined
        />
      </v-col>
      <v-col
        cols="12"
        md="4"
        lg="3"
      >
        <v-autocomplete
          v-model="currencyPaymentSelected"
          label="Moneda"
          :loading="fetchingPaymentsMethods"
          :disabled="fetchingPaymentsMethods || toCredit"
          :items="currenciesPayments"
          item-text="symbol"
          item-value="name"
          no-data-text="No hay coincidencia"
          return-object
          outlined
        />
      </v-col>
      <v-col
        cols="12"
        md="4"
        lg="2"
      >
        <v-text-field
          v-model="amountPayment"
          :disabled="toCredit"
          outlined
          label="Monto"
          type="number"
          min="1"
        />
      </v-col>

      <template v-if="showPayForm1">
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="PI4"
            label="Titular de la cuenta o email"
            outlined
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
          lg="2"
        >
          <v-text-field
            v-model="PI1"
            label="Numero de referencia"
            outlined
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
          lg="2"
        >
          <v-text-field
            v-model="PI2"
            label="Cta. Origen"
            outlined
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
          lg="2"
        >
          <v-text-field
            v-model="PI3"
            label="Cta. Destino"
            outlined
          />
        </v-col>
      </template>

      <template v-else-if="showPayForm2">
        <v-col
          cols="12"
          md="4"
          lg="2"
        >
          <v-text-field
            v-model="PI4"
            label="Número de referencia"
            value="V-"
            outlined
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
          lg="2"
        >
          <v-text-field
            v-model="PI1"
            label="Cédula"
            value="V-"
            outlined
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
          lg="2"
        >
          <vue-tel-input-vuetify
            v-model="PI2"
            label="Teléfono"
            return-masked-value
            placeholder="0000-0000000"
            :only-countries="['VE']"
            outlined
          />
          <!-- <v-text-field
            v-model="PI2"
            label="Telefono"
            outlined
          /> -->
        </v-col>
        <v-col
          cols="12"
          md="4"
          lg="2"
        >
          <!-- <v-text-field
            v-model="PI3"
            label="Banco"
            outlined
          /> -->
          <v-autocomplete
            v-model="PI3"
            label="Banco"
            :items="banks"
            outlined
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
          lg="2"
        >
          <v-text-field
            v-model="PI5"
            label="Cta. destino"
            outlined
          />
        </v-col>
      </template>

      <template v-else-if="showPayForm3">
        <v-col
          cols="12"
          md="4"
          lg="2"
        >
          <v-text-field
            v-model="PI4"
            label="Número de referencia"
            value=""
            outlined
          />
        </v-col>
      </template>

      <v-col
        cols="12"
        lg="12"
        md="12"
        class="text-center"
      >
        <v-btn
          class="mx-2"
          fab
          dark
          color="secondary"
          large
          @click="addPaymentMethod"
        >
          <v-icon dark>
            mdi-plus
          </v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-data-table
          class="elevation-0"
          no-data-text="No hay métodos cargados"
          :headers="headers"
          :items="dataSet.paymentMethods"
          :items-per-page="-1"
          :hide-default-footer="true"
          :footer-props="{
            'items-per-page-text': 'Filas por página',
            pageText: '{0}-{1} de {2}'
          }"
        >
          <template v-slot:[`item.actions`]="{ item: payment }">
            <v-btn
              v-if="payment.paymentMethodId !== PAYMENTS_METHODS.credito"
              color="red"
              class="transparent-button-icon"
              icon
              @click="deletePaymentMethod(payment.paymentMethodId)"
            >
              <v-icon>
                mdi-delete
              </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>

      <!-- ---------------------------- -->

      <template
        v-if="surplus"
      >
        <v-col
          cols="12"
          class="text-left"
        >
          <h1
            class="grey--text"
          >
            Cambios
          </h1>
        </v-col>
        <v-col
          cols="12"
          md="4"
          lg="3"
        >
          <v-autocomplete
            v-model="surplusMethodSelected"
            label="Métodos de pago"
            :loading="fetchingPaymentsMethods"
            :disabled="fetchingPaymentsMethods"
            :items="getSurplusMethods"
            item-text="description"
            no-data-text="No hay coincidencia"
            return-object
            outlined
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
          lg="3"
        >
          <v-autocomplete
            v-model="currencySurplusSelected"
            label="Moneda"
            :loading="fetchingPaymentsMethods"
            :disabled="fetchingPaymentsMethods"
            :items="currenciesSurplus"
            item-value="name"
            item-text="symbol"
            no-data-text="No hay coincidencia"
            return-object
            outlined
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
          lg="2"
        >
          <v-text-field
            v-model="amountSurplus"
            outlined
            label="Monto"
            type="number"
            min="1"
          />
        </v-col>

        <template v-if="showSurForm1">
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="SI4"
              label="Titular de la cuenta o email"
              outlined
            />
          </v-col>
          <v-col
            cols="12"
            md="4"
            lg="2"
          >
            <v-text-field
              v-model="SI1"
              label="Numero de referencia"
              outlined
            />
          </v-col>
          <v-col
            cols="12"
            md="4"
            lg="2"
          >
            <v-text-field
              v-model="SI2"
              label="Cta. Origen"
              outlined
            />
          </v-col>
          <v-col
            cols="12"
            md="4"
            lg="2"
          >
            <v-text-field
              v-model="SI3"
              label="Cta. Destino"
              outlined
            />
          </v-col>
        </template>

        <template v-else-if="showSurForm2">
          <v-col
            cols="12"
            md="4"
            lg="2"
          >
            <v-text-field
              v-model="SI4"
              label="Numero de referencia"
              value="V-"
              outlined
            />
          </v-col>
          <v-col
            cols="12"
            md="4"
            lg="2"
          >
            <v-text-field
              v-model="SI1"
              label="Cédula"
              value="V-"
              outlined
            />
          </v-col>
          <v-col
            cols="12"
            md="4"
            lg="2"
          >
            <vue-tel-input-vuetify
              v-model="SI2"
              label="Teléfono"
              return-masked-value
              placeholder="0000-0000000"
              :only-countries="['VE']"
              outlined
            />
            <!-- <v-text-field
              v-model="SI2"
              label="Telefono"
              outlined
            /> -->
          </v-col>
          <v-col
            cols="12"
            md="4"
            lg="2"
          >
            <!-- <v-text-field
              v-model="SI3"
              label="Banco"
              outlined
            /> -->
            <v-autocomplete
              v-model="SI3"
              label="Banco"
              :items="banks"
              outlined
            />
          </v-col>
          <v-col
            cols="12"
            md="4"
            lg="2"
          >
            <v-text-field
              v-model="SI5"
              label="Cta. destino"
              outlined
            />
          </v-col>
        </template>

        <template v-else-if="showSurForm3">
          <v-col
            cols="12"
            md="4"
            lg="2"
          >
            <v-text-field
              v-model="SI4"
              label="Número de referencia"
              value=""
              outlined
            />
          </v-col>
        </template>

        <v-col
          cols="12"
          class="text-center"
        >
          <v-btn
            class="mx-2"
            fab
            dark
            color="secondary"
            large
            @click="addSurplusMethod"
          >
            <v-icon dark>
              mdi-plus
            </v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12">
          <v-data-table
            class="elevation-0"
            no-data-text="No hay métodos cargados"
            :headers="headers"
            :items="dataSet.surplusMethods"
            :items-per-page="-1"
            :hide-default-footer="true"
            :footer-props="{
              'items-per-page-text': 'Filas por página',
              pageText: '{0}-{1} de {2}'
            }"
          >
            <template v-slot:[`item.actions`]="{ item: payment }">
              <v-btn
                color="red"
                class="transparent-button-icon"
                icon
                @click="deleteSurplusMethod(payment.paymentMethodId)"
              >
                <v-icon>
                  mdi-delete
                </v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </template>
    </v-row>

    <v-row
      justify="end"
    >
      <v-col
        cols="12"
        md="3"
        lg="2"
      >
        <v-btn
          text
          block
          @click="back"
        >
          Atras
        </v-btn>
      </v-col>
      <v-col
        cols="12"
        md="3"
        lg="2"
      >
        <v-btn
          color="secondary"
          block
          :disabled="!!calculateTotal"
          @click="next"
        >
          Terminar
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  // import { jsPDF as JsPdf } from 'jspdf'
  import { mapState } from 'vuex'
  import { PAYMENTS_METHODS, DIRECT_SALE } from '../../../../const'
  export default {
    name: 'StepTwo',

    props: {
      data: {
        type: Object,
        default: () => {},
      },
    },

    data () {
      return {
        PAYMENTS_METHODS,
        DIRECT_SALE,
        toCredit: false,
        paymentMethodSelected: null,
        currencyPaymentSelected: null,
        PI1: '',
        PI2: '',
        PI3: '',
        PI4: '',
        PI5: '',
        surplusMethodSelected: null,
        currencySurplusSelected: null,
        SI1: '',
        SI2: '',
        SI3: '',
        SI4: '',
        SI5: '',
        amountPayment: 1,
        amountSurplus: 1,
        surplus: false,
        headers: [
          {
            text: 'Método de pago',
            value: 'description',
          },
          {
            text: 'Moneda',
            value: 'currency',
            sortable: false,
          },
          {
            text: 'Monto',
            value: 'amount',
          },
          {
            text: 'Acciones',
            value: 'actions',
            sortable: false,

          },
        ],

        dataSet: {
          paymentMethods: [],
          surplusMethods: [],
        },

        banks: [
          '0102 - Banco Banco de Venezuela, S.A.C.A.',
          '0104 - Banco Venezolano de Crédito',
          '0105 - Banco Mercantil',
          '0108 - Banco Provincial',
          '0114 - Banco Bancaribe',
          '0115 - Banco Exterior',
          '0116 - Banco Occidental de Descuento',
          '0128 - Banco Banco Caroní',
          '0134 - Banco Banesco',
          '0138 - Banco Banco Plaza',
          '0151 - Banco BFC Banco Fondo Común',
          '0156 - Banco 100% Banco',
          '0157 - Banco Del Sur',
          '0163 - Banco Banco del Tesoro',
          '0166 - Banco Banco Agrícola de Venezuela',
          '0168 - Banco Bancrecer',
          '0169 - Banco Mi Banco',
          '0172 - Banco Bancamiga',
          '0174 - Banco Banplus',
          '0175 - Banco Bicentenario del Pueblo',
          '0177 - Banco Banfanb',
          '0191 - Banco BNC Nacional de Crédit',
        ],
      }
    },

    computed: {
      ...mapState([
        'currencies',
        'fetchingPaymentsMethods',
        'paymentMethods',
        'actionsBusiness',
      ]),

      calculateTotal () {
        let totalPaymentDolar = 0
        let totalSurplusDolar = 0
        this.dataSet.paymentMethods.forEach(item => {
          // // // // // // // // // // // // // // // // // // // // // // // console.log(this.getCurrencyValue(item.currency))
          // // // // // // // // // // // // // // // // // // // // // // // console.log('clit', item.amount / this.getCurrencyValue(item.currency))
          totalPaymentDolar = totalPaymentDolar + item.amount / this.getCurrencyValue(item.currency)
        })

        this.dataSet.surplusMethods.forEach(item => {
          // // // // // // // // // // // // // // // // // // // // // // // console.log(this.getCurrencyValue(item.currency))
          // // // // // // // // // // // // // // // // // // // // // // // console.log('clit', item.amount / this.getCurrencyValue(item.currency))
          totalSurplusDolar = totalSurplusDolar + item.amount / this.getCurrencyValue(item.currency)
        })
        // return Math.round(((this.data.calculateIVA ? this.data.total : this.data.subTotal) - totalPaymentDolar + totalSurplusDolar) * 100) / 100
        return Math.round(((this.data.total) - totalPaymentDolar + totalSurplusDolar) * 100) / 100
      },

      getPaymentMethods () {
        const res = this.paymentMethods.map(item => {
          item.description = item.description.toUpperCase()
          item.paymentMethodId = item.id
          delete item.id
          return item
        })

        return res.filter(item => item.paymentMethodId !== PAYMENTS_METHODS.credito)
      },

      getSurplusMethods () {
        // const res = this.paymentMethods.map(item => {
        //   item.description = item.description.toUpperCase()
        //   item.paymentMethodId = item.id
        //   delete item.id
        //   return item
        // })

        // // // // // // // // // // // // // console.log(res)

        const res = this.getPaymentMethods.filter(item => item.paymentMethodId === PAYMENTS_METHODS.pago_movil || item.paymentMethodId === PAYMENTS_METHODS.transferencia)

        // // // // // // // // // // // // console.log('res', res)

        return res
      },

      showPayForm1 () {
        return this.paymentMethodSelected?.paymentMethodId >= PAYMENTS_METHODS.transferencia && this.paymentMethodSelected?.paymentMethodId !== PAYMENTS_METHODS.punto_de_venta
      },

      showPayForm2 () {
        return this.paymentMethodSelected?.paymentMethodId === PAYMENTS_METHODS.pago_movil
      },

      showPayForm3 () {
        return this.paymentMethodSelected?.paymentMethodId === PAYMENTS_METHODS.punto_de_venta
      },

      showSurForm1 () {
        return this.surplusMethodSelected?.paymentMethodId >= PAYMENTS_METHODS.transferencia && this.surplusMethodSelected?.paymentMethodId !== PAYMENTS_METHODS.punto_de_venta
      },

      showSurForm2 () {
        return this.surplusMethodSelected?.paymentMethodId === PAYMENTS_METHODS.pago_movil
      },

      showSurForm3 () {
        return this.surplusMethodSelected?.paymentMethodId === PAYMENTS_METHODS.punto_de_venta
      },

      currenciesPayments () {
        // // // // // // // // // // // // // // // // // console.log('Nose que pasa')
        let res = []

        if (this.paymentMethodSelected?.paymentMethodId === PAYMENTS_METHODS.pago_movil) {
          // // // // // // // // // // // // // // // // // console.log('1', this.paymentMethodSelected?.paymentMethodId)
          res = this.currencies.filter(item => item.symbol === 'Bs' || item.symbol === '$')
        } else if (this.paymentMethodSelected?.paymentMethodId === PAYMENTS_METHODS.zelle) {
          // // // // // // // // // // // // // // // // // console.log('2', this.paymentMethodSelected?.paymentMethodId)
          res = this.currencies.filter(item => item.symbol === '$')
        } else {
          // // // // // // // // // // // // // // // // // console.log('3', this.paymentMethodSelected?.paymentMethodId)
          res = this.currencies
        }

        // // // // // // // // // // // // // // // // // console.log('res', res)

        return res
      },

      currenciesSurplus () {
        // // // // // // // // // // // // // // // // // console.log('Nose que pasa')
        let res = []

        if (this.surplusMethodSelected?.paymentMethodId === PAYMENTS_METHODS.pago_movil) {
          // // // // // // // // // // // // // // // // // console.log('1', this.surplusMethodSelected?.paymentMethodId)
          res = this.currencies.filter(item => item.symbol === 'Bs' || item.symbol === '$')
        } else if (this.surplusMethodSelected?.paymentMethodId === PAYMENTS_METHODS.zelle) {
          // // // // // // // // // // // // // // // // // console.log('2', this.surplusMethodSelected?.paymentMethodId)
          res = this.currencies.filter(item => item.symbol === '$')
        } else {
          // // // // // // // // // // // // // // // // // console.log('3', this.surplusMethodSelected?.paymentMethodId)
          res = this.currencies
        }

        // // // // // // // // // // // // // // // // // console.log('res', res)

        return res
      },
    },

    watch: {
      toCredit (v) {
        if (v) {
          this.dataSet.paymentMethods = [{
            amount: this.calculateTotal,
            amountCurrencyReference: 1,
            businessId: 1,
            currency: '$',
            currencyId: 1,
            description: 'PAGO A CREDITO',
            observation: '',
            paymentMethodId: PAYMENTS_METHODS.credito,
            status: 1,
            totalAmountCurrencyReference: 90,

          }]
        } else {
          this.dataSet.paymentMethods = []
        }
      },

      showPayForm2 (v) {
        if (v) {
          this.PI1 = 'V-'
        } else {
          this.PI1 = ''
        }
      },
      showSurForm2 (v) {
        if (v) {
          this.SI1 = 'V-'
        } else {
          this.SI1 = ''
        }
      },

      currencyPaymentSelected (v) {
        if (v) {
          if (v.symbol === '$') {
            this.amountPayment = this.calculateTotal.toFixed(2)
          } else if (v.symbol === '€') {
            this.amountPayment = (this.calculateTotal * this.getCurrencyValue('Euro')).toFixed(2)
          } else {
            this.amountPayment = this.calculateTotal * this.getCurrencyValue('Bolivar').toFixed(2)
          }
        }
      },

      currencySurplusSelected (v) {
        if (v) {
          if (v.symbol === '$') {
            this.amountSurplus = Math.abs(this.calculateTotal).toFixed(2)
          } else if (v.symbol === '€') {
            this.amountSurplus = Math.abs(this.calculateTotal * this.getCurrencyValue('Euro')).toFixed(2)
          } else {
            this.amountSurplus = Math.abs(this.calculateTotal * this.getCurrencyValue('Bolivar')).toFixed(2)
          }
        }
      },

      calculateTotal (v) {
        if (v < 0 || this.dataSet.surplusMethods.length) {
          this.surplus = true
        } else {
          this.surplus = false
        }
      },

    },

    methods: {

      addPaymentMethod () {
        if (
          this.paymentMethodSelected &&
          this.amountPayment &&
          this.currencyPaymentSelected
        ) {
          if (this.dataSet.paymentMethods.filter(item => item.paymentMethodId === this.paymentMethodSelected?.paymentMethodId).length >= 1) {
            this.dataSet.paymentMethods = this.dataSet.paymentMethods.map(item => item.paymentMethodId === this.paymentMethodSelected.paymentMethodId ? ({
              ...item,
              amount: Number(item.amount) + Number(this.amountPayment),
            }) : item)
          } else {
            // // // // // // // // // // // // // // // // // console.log('Payment observatiosn', `${this.PI1}, ${this.PI2}, ${this.PI3}, ${this.PI4}, ${this.PI5}`)
            this.dataSet.paymentMethods.push({
              ...this.paymentMethodSelected,
              observation: `${this.PI1}, ${this.PI2}, ${this.PI3}, ${this.PI4}, ${this.PI5}`,
              amount: this.amountPayment,
              currency: this.currencyPaymentSelected.symbol,
              currencyId: this.currencyPaymentSelected.id,
              amountCurrencyReference: this.getCurrencyValue(this.currencyPaymentSelected.symbol),
              totalAmountCurrencyReference: this.amountPayment / this.getCurrencyValue(this.currencyPaymentSelected.symbol),
            })
          }
          this.paymentMethodSelected = null
          this.currencyPaymentSelected = null
          this.amountPayment = 1
          this.PI1 = ''
          this.PI2 = ''
          this.PI3 = ''
          this.PI4 = ''
          this.PI5 = ''
          // // // // // // // // // // // // // // // // // // // console.log('pay', this.dataSet.paymentMethods)
        } else {
          // // // // // // // // // // // // // // // // // // // // // // console.log('error')
        }
        // if (this.paymentMethodSelected && this.amount) {
        //   // // // // // // // // // // // // // // // // // // // // // // // // console.log('producto sleccionado', this.productSelected)
        //   if (this.data.paymentMethods.filter(item => item.productId === this.productSelected.productId).length >= 1) {
        //     this.data.products = this.data.products.map(item => item.productId === this.productSelected.productId ? ({
        //       ...item,
        //       cant: Number(item.cant) + Number(this.cant),
        //     }) : item)
        //   } else {
        //     this.data.products.push({ ...this.productSelected, cant: this.cant })
        //   }
        // }
        // this.alert = false
        // this.productSelected = null
        // this.cant = 1
        // } else {
        //   this.alert = true
        // }
      },

      deletePaymentMethod (id) {
        this.dataSet.paymentMethods = this.dataSet.paymentMethods.filter(item => item.paymentMethodId !== id)
      },

      addSurplusMethod () {
        // if (this.cant <= this.getCantbySelectProduct) {
        // // // // // // // // // // // // // // // // // // // // // // console.log(this.surplusMethodSelected)

        if (
          this.surplusMethodSelected &&
          this.amountSurplus &&
          this.currencySurplusSelected
        ) {
          // // // // // // // // // // // // // // // // // // // // // // console.log('agrego')
          if (this.dataSet.surplusMethods.filter(item => item.paymentMethodId === this.productSelected?.getPaymentMethods).length >= 1) {
            this.dataSet.surplusMethods = this.dataSet.surplusMethods.map(item => item.paymentMethodId === this.surplusMethodSelected.paymentMethodId ? ({
              ...item,
              amount: Number(item.amount) + Number(this.amountSurplus),
            }) : item)
          } else {
            // // // // // // // // // // // // // // // // // console.log('observatiosn', `${this.SI1}, ${this.SI2}, ${this.SI3}, ${this.SI4}, ${this.SI5}`)
            this.dataSet.surplusMethods.push({
              ...this.surplusMethodSelected,
              observation: `${this.SI1}, ${this.SI2}, ${this.SI3}, ${this.SI4}, ${this.SI5}`,
              amount: this.amountSurplus,
              currency: this.currencySurplusSelected.symbol,
              currencyId: this.currencySurplusSelected.id,
              amountCurrencyReference: this.getCurrencyValue(this.currencySurplusSelected.symbol),
              totalAmountCurrencyReference: this.amountSurplus / this.getCurrencyValue(this.currencySurplusSelected.symbol),
            })
          }
          this.surplusMethodSelected = null
          this.currencySurplusSelected = null
          this.amountSurplus = 1
          this.SI1 = ''
          this.SI2 = ''
          this.SI3 = ''
          this.SI4 = ''
          this.SI5 = ''
        } else {
          // // // // // // // // // // // // // // // // // // // // // // console.log('error')
        }
        // if (this.paymentMethodSelected && this.amount) {
        //   // // // // // // // // // // // // // // // // // // // // // // // // console.log('producto sleccionado', this.productSelected)
        //   if (this.data.paymentMethods.filter(item => item.productId === this.productSelected.productId).length >= 1) {
        //     this.data.products = this.data.products.map(item => item.productId === this.productSelected.productId ? ({
        //       ...item,
        //       cant: Number(item.cant) + Number(this.cant),
        //     }) : item)
        //   } else {
        //     this.data.products.push({ ...this.productSelected, cant: this.cant })
        //   }
        // }
        // this.alert = false
        // this.productSelected = null
        // this.cant = 1
        // } else {
        //   this.alert = true
        // }
      },

      deleteSurplusMethod (id) {
        this.dataSet.surplusMethods = this.dataSet.surplusMethods.filter(item => item.paymentMethodId !== id)
      },

      formatPrice (v, type = 'USD') {
        return new Intl.NumberFormat(
          'en-ve',
          { style: 'currency', currency: type, minimumFractionDigits: 2 },
        ).format(v)
      },

      getCurrencyValue (currency) {
        return this.currencies.filter(item => item.name === currency || item.symbol === currency)[0]?.value
      },

      async send () {
        this.$emit('send')
      },

      next () {
        const data = {
          ...this.dataSet,
        }

        // // // // // // // // // // // // // // // // // console.log('dataSub', data)
        this.$emit('submitData', data)
        this.send()
        // // // // // // // // // // // // // // // // // // // // // console.log('ENVIO Y GUARDO: ', this.data)
        this.$emit('next', 3)
      },

      back () {
        this.$emit('back', 2)
      },
    },
  }
</script>

<style scoped>
  .transparent-button-icon {
    background-color: transparent !important;
  }
</style>
