<template>
  <v-container
    fluid
    tag="section"
  >
    <!-- {{ userBySucursalId }} -->

    <v-row>
      <v-col cols="12">
        <v-stepper
          v-model="step"
          alt-labels
        >
          <v-stepper-header>
            <v-stepper-step
              v-for="(item, i) in steps"
              :key="i+1"
              :complete="step > i+2"
              :step="i+1"
              color="secondary"
            >
              {{ item.name }}
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <!-- <v-stepper-content step="2">
              <step-before-one
                @next="nextStep"
                @back="backStep"
                @submitData="addData"
              />
            </v-stepper-content> -->
            <v-stepper-content step="1">
              <step-one
                :g-data="data"
                @next="nextStep"
                @back="backStep"
                @submitData="addData"
              />
            </v-stepper-content>

            <v-stepper-content step="2">
              <!-- Si te proguntas porque el paso 3 esta en el 2 es porque inicialmente ese era el orden, solo que se invirtio -->
              <step-three
                :data="data"
                @next="nextStep"
                @back="backStep"
                @send="send"
              />
            </v-stepper-content>

            <v-stepper-content step="3">
              <step-two
                :data="data"
                @next="nextStep"
                @back="backStep"
                @submitData="addData"
                @send="send"
              />
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  // import StepBeforeOne from './StepBeforeOne.vue'
  import StepOne from './StepOne.vue'
  import StepTwo from './StepTwo.vue'
  import StepThree from './StepThree.vue'
  import { TYPE_TRANSACTION, PRINCIPAL_WAREHOUSE } from '../../../../const'
  import { mapActions, mapMutations, mapState } from 'vuex'
  import { jsPDF as JsPdf } from 'jspdf'
  export default {
    name: 'NewSaleStepper',

    components: {
      // StepBeforeOne,
      StepOne,
      StepTwo,
      StepThree,
    },

    data () {
      return {
        step: 1,
        steps: [
          // {
          //   name: '¿Desea factura?',
          // },
          {
            name: 'Datos Generales',
          },
          {
            name: 'Confirmar presupuesto',
          },
          {
            name: 'Pagos y Cambios',
          },
        ],

        lockStep: true,
        data: {
          calculateIVA: false,
          description: '',
          products: [],
          client: null,
          subTotal: 0,
          withoutTax: 0,
          gravamen: 0,
          taxTotal: 0,
          total: 0,
          discount: 0,
          paymentMethods: [],
          surplusMethods: [],
        },
      }
    },

    computed: {
      ...mapState([
        'actionWarehouse',
        'user',
        'businessSalesResponse',
        'actionEnvironmentData',
        'actionsBusiness',
        'userBySucursalId',
      ]),
    },

    async created () {
      await this.getUsersBySucursalId({ id: this.actionsBusiness })
    },

    methods: {
      ...mapMutations({
        setError: 'SET_ERROR',
        setClient: 'FETCH_CLIENTS_SUCCESS',
      }),

      ...mapActions([
        'createBusinessSales',
        'fetchBusinessSales',
        'getUsersBySucursalId',
      ]),

      getImageByUrl (url) {
        return new Promise((resolve, reject) => {
          const img = new Image()
          img.onError = () => reject(
            new Error(`Cannot load image ${url}`),
          )
          img.onload = () => resolve(img)
          img.src = url
        })
      },

      async downloadPdf () {
        const doc = new JsPdf({
          orientation: 'portrait',
          format: 'letter',
        })
        const img = await this.getImageByUrl('/logo.png')

        const { client, products } = this.data

        doc.addImage(img, 'PNG', 10, 5, 100, 20, 'logo')
        doc.setTextColor(255, 0, 0)
        doc.text(`CÓDIGO: ${this.businessSalesResponse}`, 130, 20)
        doc.setTextColor(0, 0, 0)
        doc.setFontSize(12).text(`Fecha de emisión: ${this.formatDate(new Date())}`, 130, 27)
        doc.setFontSize(13)
        doc.setTextColor(0, 0, 0).text('Documento de identidad:', 10, 35)
        doc.setTextColor(100, 100, 100).text(client?.ci, 63, 35)
        doc.setTextColor(0, 0, 0).text('Nombres: ', 10, 42)
        doc.setTextColor(100, 100, 100).text(client?.name, 32, 42)

        if (client?.lastname) {
          doc.setTextColor(0, 0, 0).text('Apellidos:', 10, 49)
          doc.setTextColor(100, 100, 100).text(client?.lastname, 32, 49)
        }

        doc.setTextColor(0, 0, 0).text('Email:', 10, 60)
        if (client?.email) {
          doc.setTextColor(100, 100, 100).text(client.email, 25, 60)
        }

        doc.setTextColor(0, 0, 0).text('Teléfono:', 10, 67)
        if (client?.phone) {
          doc.setTextColor(100, 100, 100).text(client.phone, 32, 67)
        }

        doc.setTextColor(0, 0, 0).text('Dirección:', 10, 74)
        if (client?.direction) {
          doc.setTextColor(100, 100, 100).text(client.direction, 33, 74)
        }

        doc.setFontSize(18).setTextColor(0, 0, 0).text('Nota de entrega', 82, 90)

        doc.autoTable({
          columns: [
            { title: 'Nombre del producto', dataKey: 'description' },
            { title: 'Tipo de precio', dataKey: 'typePrice' },
            { title: 'Cantidad', dataKey: 'cant' },
            { title: 'Unidad', dataKey: 'salesUnit' },
            { title: 'Precio', dataKey: 'price' },
            { title: '% de IVA', dataKey: 'tax' },
            { title: 'Valor del impuesto', dataKey: 'amountTax' },
            { title: 'Total del producto', dataKey: 'totalAmount' },
          ],
          body: products.map(item => {
            return {
              ...item,
              cant: Math.abs(item.cant),
              price: this.formatPrice(item.cant * item[item.typePrice]),
              amountTax: this.formatPrice((item.cant * item[item.typePrice]) * (item.tax / 100)),
              totalAmount: this.formatPrice((item.cant * item[item.typePrice]) + (item.cant * item[item.typePrice]) * (item.tax / 100)),
              typePrice: item.typePrice === 'wholesalePrices' ? 'Mayor' : 'Gran mayor',
            }
          }),
          margin: {
            left: 10,
            top: 100,
          },
        })

        doc.setLineWidth(0.5).line(0, doc.internal.pageSize.height - 45, doc.internal.pageSize.width, doc.internal.pageSize.height - 45)
        doc.setFontSize(10)
        doc.setTextColor(0, 0, 0)
        doc.text('Observaciones:', 10, doc.internal.pageSize.height - 37)
        doc.setFontSize(8)
        doc.text(this.data.description, 10, doc.internal.pageSize.height - 20)
        doc.setFontSize(12).setTextColor(100, 100, 100).text(`Subtotal: ${this.formatPrice(this.data.subTotal)}`, doc.internal.pageSize.width - 55, doc.internal.pageSize.height - 37)
        doc.setFontSize(10).setTextColor(100, 100, 100).text(`Exento de IVA: ${this.formatPrice(this.data.withoutTax)}`, doc.internal.pageSize.width - 55, doc.internal.pageSize.height - 31)
        doc.setFontSize(10).setTextColor(100, 100, 100).text(`Gravamen: ${this.formatPrice(this.data.gravamen)}`, doc.internal.pageSize.width - 55, doc.internal.pageSize.height - 25)
        doc.setFontSize(10).setTextColor(100, 100, 100).text(`Total del IVA: ${this.formatPrice(this.data.taxTotal)}`, doc.internal.pageSize.width - 55, doc.internal.pageSize.height - 19)
        doc.setFontSize(18).setTextColor(0, 0, 0).text(`Total: ${this.formatPrice(this.data.total)}`, doc.internal.pageSize.width - 60, doc.internal.pageSize.height - 7)

        doc.autoPrint()
        doc.output('dataurlnewwindow')
      },

      formatPrice (v, type = 'USD') {
        return new Intl.NumberFormat('en-ve', { style: 'currency', currency: type, minimumFractionDigits: 2 }).format(v)
      },

      formatDate (dateProp) {
        const date = new Date(dateProp)

        const year = date.getFullYear()
        const month = date.getMonth() + 1
        const day = new Date(year, month, date.getDate() + 1).getDate()

        if (month < 10) {
          return (`${day}/0${month}/${year}`)
        } else {
          return (`${day}/${month}/${year}`)
        }
      },

      async send () {
        // // // // // // // // // // // // // // // // // // // // // // console.log('SEND_DATA', this.data)
        await this.createBusinessSales(this.data)
        if (this.data.calculateIVA) {
          // // // // // // // // // // // // // // // // // // // console.log('businessSalesResponse', this.businessSalesResponse)
          await this.downloadPdf()
        }

        // actualiza
        await this.fetchBusinessSales({ id: this.actionWarehouse })
        this.data = {
          description: '',
          products: [],
          paymentMethods: [],
          surplusMethods: [],
          client: null,
          subTotal: 0,
          withoutTax: 0,
          gravamen: 0,
          taxTotal: 0,
          total: 0,
          discount: 0,
        }
        this.setClient([])
        this.$router.push(`/adminis/business_/${this.actionsBusiness}`)
        // this.$router.push('/admin/business/sales')
      },

      addData (data) {
        // // // // // // // // // // // // // // // // // // // // // // // // console.log(data.products, data.products.length >= 1, data.client, data.products.length >= 1 && data.client)
        if (this.step === 'Suspiro') {
          // // // // // // // // // // // // // // // // // // // // console.log('data', data)
          this.data = {
            ...this.data,
            ...data,
          }
          // // // // // // // // // // // // // // // // // // // // console.log('DATA', this.data)
          this.lockStep = false
        } else if (this.step === 1) {
          this.lockStep = true
          if (data.products.length >= 1 && data.client.id) {
            // // // // // // // // // // // // // // // // // // // // // // // console.log('los datos son correctos')
            this.data = {
              ...this.data,
              ...data,
            }
            this.lockStep = false
          } else {
            // // // // // // // // // console.error('ERROR')
            this.setError('Faltan campos por llenar')
          }
        } else if (this.step === 3) {
          // // // // // // // // // // // // // // // // // // // // // // console.log('por aqui 2')
          this.data = {
            ...this.data,
            ...data,
          }
        }

        this.data = {
          ...this.data,
          typeTransactionId: TYPE_TRANSACTION.venta_por_concesion,
          // userId: this.user.id,
          warehouseId: this.actionWarehouse,
          relatedWarehouseId: PRINCIPAL_WAREHOUSE,
          code: this.actionEnvironmentData.code,
          sucursalId: this.actionsBusiness,
        }

        // // // // // // // // // // // // // // // // // // // // // // // // console.log('DATA', this.data)
      },

      nextStep (v) {
        // // // // // // // // // // // // // // // // // // // // console.log('Llegue on')
        if (!this.lockStep) {
          // // // // // // // // // // // // // // // // // // // // console.log('Paso o no?')
          this.step = v
        }
      },

      backStep (v) {
        this.step = v
      },
    },
  }
</script>
