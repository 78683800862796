<template>
  <div>
    <v-row>
      <v-col
        cols="12"
      >
        <h1 class="grey--text">
          Cliente
        </h1>
      </v-col>

      <v-col
        cols="12"
        md="4"
      >
        <v-text-field
          v-model="ci"
          filled
          disabled
          label="Documento de indentidad"
        />
      </v-col>

      <v-col
        cols="12"
        md="8"
        lg="4"
      >
        <v-text-field
          v-model="fullname"
          filled
          disabled
          label="Nombre"
        />
      </v-col>

      <v-col
        cols="12"
      >
        <h1 class="grey--text">
          Productos
        </h1>
      </v-col>

      <v-col
        cols="12"
      >
        <v-data-table
          class="elevation-0"
          no-data-text="No hay productos cargados"
          :headers="headers"
          :items="data.products"
          :items-per-page="-1"
          :hide-default-footer="true"
        >
          <template v-slot:[`item.typePrice`]="{ item: product }">
            <span>{{ product.typePrice === 'retailPrice' ? 'Al detal' : product.typePrice === 'wholesalePrices' ? 'Mayor' : 'Gran mayor' }}</span>
          </template>

          <template v-slot:[`item.price`]="{ item: product }">
            <span>{{ formatPrice(product[product.typePrice] * product.cant) }}</span>
          </template>

          <template v-slot:[`item.taxPrice`]="{ item: product }">
            <span>{{ formatPrice((product[product.typePrice] * product.cant) * (product.tax/100)) }}</span>
          </template>

          <template v-slot:[`item.totalPrice`]="{ item: product }">
            <span class="green--text">{{ formatPrice((product[product.typePrice] * product.cant) + (product[product.typePrice] * product.cant) * (product.tax/100)) }}</span>
          </template>
        </v-data-table>
        <hr>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-textarea
          v-model="data.description"
          label="Observaciones"
          outlined
          rows="6"
          disabled
          no-resize
        />
      </v-col>
      <!-- <v-col
        cols="12"
        md="6"
        lg="4"
      >
        <span v-if="data.calculateIVA">
          <h2> <span class="grey--text">Subtotal: </span> <span class="green--text">{{ formatPrice(data.subTotal) }}</span></h2>
          <h3> <span class="grey--text">Exento de IVA: </span> <span class="green--text">{{ formatPrice(data.withoutTax) }}</span></h3>
          <h3> <span class="grey--text">Gravamen: </span> <span class="green--text">{{ formatPrice(data.gravamen) }}</span></h3>
          <h3><span class="grey--text">Total del IVA: </span> <span class="green--text">{{ formatPrice(data.taxTotal) }}</span></h3>
          <hr>
          <br>
        </span>
        <h2> <span class="grey--text">Subtotal: </span> <span class="green--text">{{ formatPrice(data.subTotal) }}</span></h2>
        <h2> <span class="grey--text">% de descuento: </span> <span class="green--text">{{ data.discount }}%</span></h2>
        <h2> <span class="grey--text">Monto descuento: </span> <span class="green--text">{{ formatPrice(data.subTotal - data.total) }}</span></h2>
        <h1>Total: <span class="green--text">{{ formatPrice(data.total) }}</span> <span class="grey--text">|</span> <span class="orange--text">{{ formatPrice((data.total) * getCurrencyValue('Euro'), 'EUR') }}</span> <span class="grey--text">|</span> <span class="info--text">{{ formatPrice((data.total) * getCurrencyValue('Bolivar'), 'VES') }}</span></h1>
        <!- <h1>Total: <span class="green--text">{{ formatPrice((!data.calculateIVA ? data.subTotal : data.total)) }}</span> <span class="grey--text">|</span> <span class="orange--text">{{ formatPrice((!data.calculateIVA ? data.subTotal : data.total) * getCurrencyValue('Euro'), 'EUR') }}</span> <span class="grey--text">|</span> <span class="info--text">{{ formatPrice((!data.calculateIVA ? data.subTotal : data.total) * getCurrencyValue('Bolivar'), 'VES') }}</span></h1> --
      </v-col> -->

      <v-col
        cols="8"
        sm="4"
      >
        <h2> <span class="grey--text">Subtotal: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></h2>
        <h2>
          <span
            class="grey--text"
          >
            % de descuento: &nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </h2>
        <h2>
          <span class="grey--text">Monto descuento: &nbsp;</span>
        </h2>
        <h1>
          Total: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </h1>
      </v-col>
      <v-col
        cols="4"
        sm="2"
      >
        <h2> <span class="green--text">{{ formatPrice(data.subTotal) }}</span></h2>
        <h2> <span class="green--text">{{ data.discount }}%</span> </h2>
        <h2> <span class="green--text">{{ formatPrice(data.subTotal - data.total) }}</span></h2>
        <h1>
          <span class="green--text">{{ formatPrice(data.total) }}</span> <span class="grey--text">|</span> <span class="orange--text">{{ formatPrice((data.total) * getCurrencyValue('Euro'), 'EUR') }}</span> <span class="grey--text">|</span> <span class="info--text">{{ formatPrice((data.total) * getCurrencyValue('Bolivar'), 'VES') }}</span>
        </h1>
      </v-col>
      <!-- <v-col
        cols="12"
      >
        <h1 class="grey--text">
          Pagos
        </h1>
      </v-col>
      <v-col cols="12">
        <v-data-table
          class="elevation-0"
          no-data-text="No hay productos cargados"
          :headers="headersMethods"
          :items="data.paymentMethods"
          :items-per-page="-1"
          :hide-default-footer="true"
        />
        <hr>
      </v-col>
      <template
        v-if="showSurplus"
      >
        <v-col
          cols="12"
        >
          <h1 class="grey--text">
            Cambios
          </h1>
        </v-col>
        <v-col cols="12">
          <v-data-table
            class="elevation-0"
            no-data-text="No hay productos cargados"
            :headers="headersMethods"
            :items="data.surplusMethods"
            :items-per-page="-1"
            :hide-default-footer="true"
          />
          <hr>
        </v-col>
      </template> -->
    </v-row>

    <v-row
      justify="end"
    >
      <v-col
        cols="12"
        md="3"
        lg="2"
      >
        <v-btn
          text
          block
          @click="back"
        >
          Atras
        </v-btn>
      </v-col>
      <v-col
        cols="12"
        md="3"
        lg="2"
      >
        <v-btn
          color="secondary"
          block
          :loading="fetchingBusinessSales"
          @click="send"
        >
          Confirmar
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import { jsPDF as JsPdf } from 'jspdf'
  import 'jspdf-autotable'
  export default {
    name: 'StepThree',

    props: {
      data: {
        type: Object,
        default: () => {},
      },
    },

    data () {
      return {
        checkIn: true,
        headersMethods: [
          {
            text: 'Método de pago',
            value: 'description',
          },
          {
            text: 'Moneda',
            value: 'currency',
            sortable: false,
          },
          {
            text: 'Monto',
            value: 'amount',
          },
        ],
      }
    },

    computed: {
      ...mapState([
        'currencies',
        'fetchingBusinessSales',
      ]),

      headers () {
        return this.data.calculateIVA ? [
          {
            text: 'Nombre del producto',
            value: 'description',
          },
          {
            text: 'Tipo de precio',
            value: 'typePrice',
            sortable: false,
          },
          {
            text: 'Cantidad',
            value: 'cant',
          },
          {
            text: 'Precio',
            value: 'price',
          },
          {
            text: '% de IVA',
            value: 'tax',
          },
          {
            text: 'Valor del impuesto',
            value: 'taxPrice',
          },
          {
            text: 'Total del producto',
            value: 'totalPrice',
          },
        ] : [
          {
            text: 'Nombre del producto',
            value: 'description',
          },
          {
            text: 'Tipo de precio',
            value: 'typePrice',
            sortable: false,
          },
          {
            text: 'Rollos',
            value: 'packingCant',
          },
          // {
          //   text: 'Unidad de empaque',
          //   value: 'packingUnit',
          // },
          {
            text: 'KG',
            value: 'cant',
          },
          // {
          //   text: 'Unidad',
          //   value: 'salesUnit',
          // },
          {
            text: 'Precio',
            value: 'price',
          },
        ]
      },

      fullname () {
        return this.data.client?.name + ' ' + this.data.client?.lastname
      },

      ci () {
        return this.data.client?.ci
      },

      showSurplus () {
        // // // // // // // // // // // // // // // // // // // // // // console.log('ARRY', this.data?.surplusMethods)
        return this.data?.surplusMethods?.length > 0
      },
    },

    methods: {
      getImageByUrl (url) {
        return new Promise((resolve, reject) => {
          const img = new Image()
          img.onError = () => reject(
            new Error(`Cannot load image ${url}`),
          )
          img.onload = () => resolve(img)
          img.src = url
        })
      },
      async downloadPdf () {
        const doc = new JsPdf({
          orientation: 'portrait',
          format: 'letter',
        })
        const img = await this.getImageByUrl('/logo.png')

        const { client, products } = this.data

        doc.addImage(img, 'PNG', 15, 20, 100, 20, 'logo')

        doc.setFontSize(15)
        doc.text('Datos del cliente', 82, 48)
        doc.setFontSize(10)
        doc.text(`Documento de identidad: ${client?.ci}`, 10, 58)
        doc.text(`Nombre: ${client?.name}`, 10, 68)
        doc.text(`Apellido: ${client?.lastname}`, 10, 78)
        doc.text(`Telefono: ${client?.phone}`, 10, 88)
        doc.text(`Direccion: ${client?.direction}`, 10, 98)
        doc.setFontSize(15)
        doc.text('Nota de entrega', 82, 120)

        doc.autoTable({
          columns: [
            { title: 'Nombre del producto', dataKey: 'description' },
            { title: 'Tipo de precio', dataKey: 'typePrice' },
            { title: 'Cantidad', dataKey: 'cant' },
            { title: 'Unidad', dataKey: 'salesUnit' },
            { title: 'Precio', dataKey: 'price' },
            { title: '% de IVA', dataKey: 'tax' },
            { title: 'Valor del impuesto', dataKey: 'amountTax' },
            { title: 'Total del producto', dataKey: 'totalAmount' },
          ],
          body: products.map(item => {
            return {
              ...item,
              price: item.cant * item[item.typePrice],
              amountTax: (item.cant * item[item.typePrice]) * (item.tax / 100),
              totalAmount: (item.cant * item[item.typePrice]) + (item.cant * item[item.typePrice]) * (item.tax / 100),
              typePrice: item.typePrice === 'wholesalePrices' ? 'Al Mayor' : 'Al detal',
            }
          }),
          margin: {
            left: 10,
            top: 130,
          },
        })

        doc.setFontSize(10)
        doc.text(`Subtotal: ${this.data.subTotal}`, 10, doc.internal.pageSize.height - 50)
        doc.text(`Exento de IVA: ${this.data.withoutTax}`, 10, doc.internal.pageSize.height - 40)
        doc.text(`Gravamen: ${this.data.gravamen}`, 10, doc.internal.pageSize.height - 30)
        doc.text(`Total del IVA: ${this.data.taxTotal}`, 10, doc.internal.pageSize.height - 20)
        doc.setFontSize(15)
        doc.text(`Total: ${this.data.total}`, 10, doc.internal.pageSize.height - 10)

        doc.save('facture.pdf')
      },
      async send () {
        // this.$emit('send')
        this.next()
        // if (this.data.calculateIVA) {
        //   await this.downloadPdf()
        // }
      },
      formatPrice (v, type = 'USD') {
        return new Intl.NumberFormat('en-ve', { style: 'currency', currency: type, minimumFractionDigits: 2 }).format(v)
      },

      getCurrencyValue (currency) {
        return this.currencies.filter(item => item.name === currency || item.symbol === currency)[0]?.value
      },

      next () {
        this.$emit('next', 3)
      },

      back () {
        this.$emit('back', 1)
      },
    },
  }
</script>
