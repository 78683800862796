<template>
  <new-sale-stepper />
</template>

<script>
  import { mapActions, mapState, mapMutations } from 'vuex'
  import NewSaleStepper from '../components/business-new-sale/NewSaleStepper.vue'
  export default {
    name: 'BusinessNewSale',

    components: {
      NewSaleStepper,
    },

    computed: {
      ...mapState([
        'user',
        'actionWarehouse',
        'actionsParentBusiness',
        'warehouses',
      ]),
    },

    watch: {
      async warehouses (v) {
        // // // // console.log('almacen de la tienda', v[0])
        this.setActionWarehouse(v[0].warehouseId)
        await this.fetchStoreInventory({ id: v[0].warehouseId })
        // await this.getBusinessChartData({ warehouseId: v[0].warehouseId, businessId: this.actionsBusiness })
      },
    },

    async created () {
      this.setActions(Number(this.$route.params.id))
      this.setEnvironment('business')
      await this.getWarehousesByParentId({ id: this.$route.params.id })

      await this.getCurrencies({ id: this.user.businessId || this.actionsParentBusiness })
      await this.fetchProducts({ id: this.user.businessId || this.actionsParentBusiness })
      await this.fetchStoreInventory({ id: this.actionWarehouse })
      await this.fetchPaymentsMethods()
    },

    methods: {
      ...mapActions([
        'fetchStoreInventory',
        'fetchProducts',
        'getCurrencies',
        'fetchPaymentsMethods',
        'getWarehousesByParentId',
        'fetchStoreInventory',
        'getBusinessChartData',
      ]),
      ...mapMutations({
        setEnvironment: 'SET_ACTIONS_ENVIRONMENT',
        setActions: 'SET_ACTIONS_BUSINESS',
        setActionWarehouse: 'SET_ACTIONS_WAREHOUSE',
      }),
    },
  }
</script>
